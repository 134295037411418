import * as firebase from "firebase/app";
import "firebase/firestore";
import { getFirestore, initializeFirestore } from "firebase/firestore";
import {getAuth, signInWithPopup, TwitterAuthProvider} from "firebase/auth"

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCca7krErraG_iCloQaR1TD81F1rKe3Qpo",
  authDomain: "earn-to-stay.firebaseapp.com",
  projectId: "earn-to-stay",
  storageBucket: "earn-to-stay.appspot.com",
  messagingSenderId: "159329511852",
  appId: "1:159329511852:web:71f858a47663a094eab209"
};
// Initialize Firebase
export const app = firebase.initializeApp(firebaseConfig);

const twitter = new TwitterAuthProvider();

// window.twitter = twitter;
// window.getAuth = getAuth;
// window.signInWithPopup = signInWithPopup;