import { useRef, useState } from "react";
import { useEffect } from "react";
import { Transition } from "react-transition-group";

export function TipContent({ content: SELECTED_TIP, onComplete }) {

    // goal: when content is larger than el width, scroll by 1 character at a time
    // flip direction after reaching scroll end
    
    const [scrollDirection, setScrollDirection] = useState('right');
    const [FADING, setFading] = useState(false);
    const intervalRef = useRef();
    const lastTime = useRef(Date.now());
    // note: use lastTime to derive the delta in order to smooth scroll

    useEffect(() => {
        setScrollDirection('right');
        ClearIntervalRef();
        const el = TipContentEl();
        if (el) {
            el.scrollLeft = 0;
        }
    }, [SELECTED_TIP]);

    useEffect(() => {
        ClearIntervalRef();

        let timeout = setTimeout(() => {
            const el = TipContentEl();
            if(!el) return;
            
            
            intervalRef.current = setInterval(() => {
                // if the content is larger than the el width, start scrolling
                if (el.scrollWidth <= el.clientWidth) return setScrollDirection(scrollDirection === 'right' ? 'left' : 'right');
                // add 1 character width to scrollLeft if direction is right
                if(scrollDirection === 'right') {
                    el.scrollLeft += 2;

                    // if scrollLeft is at the end, flip direction
                    if(el.scrollLeft >= el.scrollWidth - el.clientWidth) {
                        setScrollDirection('left');
                    }

                } else if(scrollDirection === 'left') {
                    el.scrollLeft -= 6.66;

                    // if scrollLeft is at the end, flip direction
                    if(el.scrollLeft <= 0) {
                        setScrollDirection('right');
                        // 33% chance of triggering onComplete
                        if(Math.random() < 0.6) {
                            console.log('onComplete');
                        onComplete ? setFading(true) : (() => {})();
                        }
                    }
                    
                }
                
            }, scrollDirection == 'right' ? 80 : 80);
        }, scrollDirection === 'right' ? 5000 : 3000);

        return () => {
            clearTimeout(timeout);
            if(intervalRef) {
                clearInterval(intervalRef.current);
                intervalRef.current = null;
            }
        }

    }, [SELECTED_TIP, scrollDirection]);

    useEffect(() => {
        if(FADING) {
            setTimeout(() => {
                console.log("Triggering onComplete");
                onComplete ? onComplete() : (() => {})();
            }, 800)
        }
    }, [FADING]);


  return (
          <div className={`tip-content`}>
            <Transition in={FADING} timeout={1000}>
                {s => {
                    if(s === 'entered') setFading(false);
                    return <div className={s}>{SELECTED_TIP}</div>
                }}
            </Transition>
          </div>
        );

    function ClearIntervalRef() {
        if (intervalRef) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }
    }

    function TipContentEl() {
        return document.getElementsByClassName('tip-content')[0] || null;
    }
}
