import { Card } from "../Card";
import { useScreenWidth } from "../_hooks/useScreenWidth";
import {
  BsFilterRight,
  BsChatRightText,
  BsPinAngleFill,
  BsTriangleFill,
} from "react-icons/bs";
import { useEffect, useRef, useState } from "react";
import { Transition } from "react-transition-group";
import "./Dashboard.css";
import { app } from "../firebase";
import {
  collection,
  collectionGroup,
  doc,
  getFirestore,
  initializeFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { getApp } from "firebase/app";
import Blockies from "react-blockies";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useTips } from "../_hooks/useTips";
import { TipContent } from "./TipContent.jsx";
import { BonusTasks } from "./BonusTasks.jsx";
import { Portal } from "react-portal";
import { useActivityFeed } from "../_hooks/useActivityFeed";
import { RecruitPage } from "./RecruitPage.jsx";

export function Dashboard({ provider, accounts, user }) {
  const [pullUpStyle, setPullUpStyle] = useState({});
  const [pullUpTitle, setPullUpTitle] = useState(null);
  const pullUpContent = useRef(<></>);
  const [userData, setUserData] = useState(null);
  const [showChat, setShowChat] = useState(false);
  const [showChatButton, setShowChatButton] = useState(false);
  const [showBetaStuff, setShowBetaStuff] = useState(false);
  const [refInfo, setRefInfo] = useState(null);
  const [processing, setProcessing] = useState(false);

  const [manaForStudying, setManaForStudying] = useState("0");
  const feed = useActivityFeed();

  const [selectedCard, setSelectedCard] = useState(null);

  useEffect(() => {
    if (
      manaForStudying == "0" &&
      userData?.stats?.mana > 0 &&
      pullUpTitle == null
    )
      setManaForStudying(userData.stats.mana.toString());
  }, [manaForStudying, userData, pullUpTitle]);

  const FLOATING_MANA = userData ? userData.stats.floatingMana : "0";
  const SIPHONED_MANA = 0;
  const SIPHON_RATE = 1; // % siphoned every 8 hours

  const REF_LINK = refInfo ? `https://iearn.info/?r=${refInfo?.id}` : "";

  let level = userData?.stats?.level || 0;

  useEffect(() => {
    if (!user) return;
    initializeFirestore(getApp(), {});
    let firestore = getFirestore(app);
    let docRef = doc(firestore, `users/${user.uid}`);
    // call getRef callable
    let removelistener = onSnapshot(docRef, (doc) => {
      if (doc.exists) {
        setUserData(doc.data());
      }
    });
    return () => removelistener();
  }, [user]);

  useEffect(() => {
    if (refInfo) return;
    // TODO only call this once the refCode panel has been clicked on
    // (async () => {
    //   // call getRefCode
    //   let ref = await httpsCallable(getFunctions(app), "getRefCode")();
    //   setRefInfo(ref.data);
    // })();
  }, [showBetaStuff, refInfo]);

  useEffect(() => {
    const WHITELIST = [
      "0xB72C0Bd8e68De7de2Bf99abe238Ad7d18F9daaF7",
      "0x5a05b1e796a33b546aD5b2e61B350749dc23DE45",
      "0xacF479789b393B610978f703837dC987886896A1",
      "0x69d40f6eCba8D20ebE43393e92BE0d15F338233a"
    ];
    if (WHITELIST.includes(accounts[0])) {
      // setShowChatButton(true);
      setShowBetaStuff(true);
    }
  }, [accounts]);

  // useEffect(() => {
  //   if(!user) return;
  //   let firestore = getFirestore(app);
  //   let col = collectionGroup(firestore, `activity`);
  //   let _o = orderBy("timestamp", "desc")
  //   let _l = limit(1)
  //   let removelistener = onSnapshot(query(col, _o, _l), (res) => {
  //     let data = res.docs.map(doc => doc.data());
  //     console.log(data);
  //   })

  //   return () => removelistener();
  // }, [user])

  useEffect(() => {
    let listener;
    if (pullUpTitle?.length) {
      // listen to escape key press and close the pull up
      listener = document.body.addEventListener("keydown", (e) => {
        if (e.key === "Escape") {
          setPullUpTitle("");
          setPullUpStyle({});
          pullUpContent.current = <></>;
          setSelectedCard(null);
        }
      });
    }
    return () => {
      if (listener) {
        document.body.removeEventListener("keydown", listener);
      }
    };
  }, [pullUpTitle]);

  const width = useScreenWidth();
  const cards = GenerateCards();

  useEffect(() => {
    if (selectedCard || selectedCard === 0) {
      const card = cards.find((i) => i?.id == selectedCard);
      if (card) {
        console.log("card", card);
        card.onClick();
        return;
      }
    }

    if (selectedCard == null) return;
    setPullUpTitle(null);
    setPullUpStyle({});
    setSelectedCard(null);
    pullUpContent.current = <></>;
  }, [selectedCard, userData]);

  // const IS_SHOWING_ACTIVITY_FEED = level >= 1 && showBetaStuff;
  const IS_SHOWING_ACTIVITY_FEED = level >= 0;
  const {
    tip: SELECTED_TIP,
    tips,
    index: tipIndex,
    increment,
    decrement,
  } = useTips(level, userData);
  const [IS_SHOWING_TIPS, _setIsShowingTips] = useState(true);

  return !userData ? null : (
    <div className="Dashboard">
      <div className="header">
        <div className="bars">
          <div
            className="bar feed tips"
            style={{ display: IS_SHOWING_TIPS ? "inherit" : "none" }}
          >
            <div className="title" style={{ zIndex: 3 }}>
              TIPS
            </div>
            <TipContent
              content={SELECTED_TIP}
              onComplete={() => {
                console.log("next tip!");
                increment();
              }}
            />
            {tips.length > 1 ? (
              <>
                <div className="decrement" onClick={decrement}>
                  <BsTriangleFill></BsTriangleFill>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  {tipIndex + 1}
                  <hr style={{ width: "100%", borderColor: "#00090b" }} />
                  {tips.length}
                </div>
                <div className="increment" onClick={increment}>
                  <BsTriangleFill></BsTriangleFill>
                </div>
              </>
            ) : null}
          </div>
          <div className="bar mana">
            <div
              className="mana-background"
              style={{
                width: `${
                  (userData.stats.mana / userData.stats.manaMax) * 100
                }%`,
              }}
            ></div>
            <div className="title">MANA</div>
            <div className="amount">
              {userData.stats.mana}
              <span>
                {" "}
                (
                {userData.stats.manaMax
                  .toString()
                  .split("")
                  .reverse()
                  .reduce((a, b, i) => {
                    a.unshift(b);
                    if (i % 3 == 2) a.unshift(",");
                    return a;
                  }, [])
                  .join("")}{" "}
                MAX)
              </span>
            </div>
          </div>
          {true ? (
            <div className="bar xp">
              <div
                className="xp-background"
                style={{
                  width: `${
                    (userData.stats.xp / userData.stats.xpToNextLevel) * 100
                  }%`,
                }}
              ></div>
              <div className="title">XP</div>
              <div className="lvl-amount">
                LVL {level}
                {/*<br /><span>Rank: Student</span>*/}
              </div>
              <div className="amount">
                {userData?.stats?.xp.toFixed(0) || 0}{" "}
                <span>
                  (
                  {(
                    userData?.stats?.xpToNextLevel - userData?.stats?.xp
                  ).toFixed(0) || "1,000"}{" "}
                  left)
                </span>
              </div>
            </div>
          ) : null}
          <div
            className="bar feed"
            style={{ display: IS_SHOWING_ACTIVITY_FEED ? "inherit" : "none" }}
          >
            <div className="title">FEED</div>
            <div className="filter">
              <BsFilterRight />
            </div>
            <ul className="the-feed">
              {[/*{address:"0x1234567890", data: {amount: Math.floor(10000*Math.random())}},
              {address:"0x1234567890", data: {amount: Math.floor(10000*Math.random())}},
              {address:"0x1234567890", data: {amount: Math.floor(10000*Math.random())}},
              {address:"0x1234567890", data: {amount: Math.floor(10000*Math.random())}},*/
              ...feed].map((_, i) => (
                <li style={{
                  'animation': `feedFadeIn 0.35s ease-in-out ${(i * 0.15)}s forwards`
                }} key={_.date}>
                  <div className="address">
                    {_.address.slice(0, 5)}...{
                      _.address.slice(-3)
                    } <br />+{_.data.amount} 💧
                  </div>
                </li>
              ))}
              
            </ul>
          </div>
        </div>
        <div className="account">
          {accounts[0].slice(0, 5)}...
          {accounts[0].slice(accounts[0].length - 3, accounts[0].length)}
        </div>
      </div>
      <Portal></Portal>
      <div
        className="cards"
        style={{
          marginTop: `calc(${IS_SHOWING_ACTIVITY_FEED ? " 2em " : "0em "} + ${
            IS_SHOWING_TIPS ? " 2.5em " : "0em "
          })`,
        }}
      >
        {width > 420 ? (
          <>
            <div>
              {cards.map((card, index) =>
                index % 2 == 0 ? (
                  card ? (
                    <Card
                      key={index}
                      {...card}
                      setSelectedCard={setSelectedCard}
                    />
                  ) : null
                ) : null
              )}
            </div>
            <div>
              {cards.map((card, index) =>
                index % 2 == 1 ? (
                  card ? (
                    <Card
                      key={index}
                      {...card}
                      setSelectedCard={setSelectedCard}
                    />
                  ) : null
                ) : null
              )}
            </div>
          </>
        ) : (
          <div>
            {cards.map((card, index) =>
              card ? (
                <Card key={index} {...card} setSelectedCard={setSelectedCard} />
              ) : null
            )}
          </div>
        )}
      </div>

      <Portal>
        <div id="pullups">
          {/* Chat Button Transition */}
          <Transition in={showChatButton} timeout={800}>
            {(s) => {
              return (
                <button
                  className={`open-chat ${s}`}
                  onClick={() => setShowChat(true)}
                >
                  <BsChatRightText />
                </button>
              );
            }}
          </Transition>

          {/* Chat Box Transition */}
          <Transition in={!!pullUpTitle?.length} timeout={800}>
            {(state) => {
              return (
                <>
                  <div
                    className={`pull-up overlay ${state} ${
                      pullUpStyle?.invert ? "invert" : ""
                    }`}
                    style={{ backgroundColor: pullUpStyle?.backgroundColor }}
                  >
                    <div
                      className={`pull-up-exit ${state} ${
                        pullUpStyle?.invert ? "invert" : ""
                      }`}
                    >
                      <button
                        style={{
                          borderColor: pullUpStyle?.invert
                            ? "#00090b"
                            : "white",
                          color: pullUpStyle?.backgroundColor,
                          backgroundColor: pullUpStyle?.invert
                            ? "#00090b"
                            : "white",
                        }}
                        onClick={() => {
                          setPullUpTitle("");
                          setPullUpStyle({});
                          setSelectedCard(null);
                          pullUpContent.current = <></>;
                        }}
                      >
                        <span
                          style={{
                            color: pullUpStyle?.backgroundColor,
                          }}
                        >
                          X
                        </span>
                      </button>
                    </div>
                    <h1
                      className={`${pullUpStyle?.invert ? "invert" : ""}`}
                      style={{
                        color: pullUpStyle?.invert ? "#00090b" : "white",
                      }}
                    >
                      {pullUpTitle}
                    </h1>
                    <div
                      className={`pull-up-content ${state} ${
                        pullUpStyle?.invert ? "invert" : ""
                      }`}
                    >
                      {pullUpContent.current}
                    </div>
                  </div>
                </>
              );
            }}
          </Transition>
          <TrollBox
            showChat={showChat}
            pullUpStyle={pullUpStyle}
            setShowChat={setShowChat}
          />
        </div>
      </Portal>
    </div>
  );

  function GenerateCards() {
    return [
      {
        title: "MEDITATION ZONE",
        subtitle: "WATCH & GENERATE MANA PASSIVELY",
        button: "MEDITATE",
        color: "#3AC6CB",
        onClick: () => {
          // BringUpMenu("MEDITATION ZONE", {
          //   backgroundColor: "#3AC6CB"
          // }, <>Hey there!</>)
          window.open(
            `https://api.lootably.com/api/offerwall/redirect/offer/101-999?placementID=cl38sytqh02hs01066hq262jy&rawPublisherUserID=${accounts[0].toLowerCase()}`,
            "_blank"
          );
        },
      },
      // level >= 0 ? {
      //   title: "BONUS TASKS",
      //   subtitle: "SIMPLE ACTIONS TO GAIN MANA",
      //   button: "VIEW TASKS",
      //   color: "#ffd722",
      //   invert:true,
      //   style: {
      //     minHeight: '10em'
      //   },
      //   onClick: () => {
      //     BringUpMenu("  ", {
      //       backgroundColor: "#ffd722",
      //       invert: true,
      //     }, <BonusTasks user={userData} />)
      //   },
      // } : undefined,
      // level >= 0 ? null : undefined,
      {
        title: "QUESTS",
        subtitle: "TASKS CAN REWARD YOU WITH MASSIVE AMOUNTS OF MANA!",
        color: "#F11515",
        button: "QUEST",
        onClick: () => {
          // BringUpMenu(`QUESTS`, {
          //   backgroundColor: "#F11515"
          // }, <>Hey there!</>)
          window.open(
            `https://wall.lootably.com/?placementID=cl38sytqh02hs01066hq262jy&sid=${accounts[0].toLowerCase()}`,
            "_blank"
          );
        },
      },
      {
        title: "LIBRARY",
        subtitle: (
          <span style={{ color: "#00090b" }}>
            EXPEND STORED MANA FOR XP <br /> <br />
            <span style={{ fontSize: "0.75ch", color: "inherit" }}>
              UNLOCKS FURTHER PROGRESSIONS
            </span>
          </span>
        ),
        button: "STUDY",
        color: "#07f55e",
        invert: true,
        onClick: () => {
          if (userData?.stats?.mana)
            setManaForStudying(userData?.stats?.mana.toString());
          BringUpMenu(
            `LIBRARY`,
            {
              backgroundColor: "#07f55e",
              invert: true,
            },
            <RenderLibrary userData={userData} provider={provider} />
          );
        },
      },
      // ,null,
      // {
      //   title: "LEADERBOARD",
      //   subtitle: <>RANK #782 <br /><br /> <span style={{ fontSize: "0.75ch" }}>BOTTOM 10% STUDENTS CULLED WEEKLY</span></>,
      //   color: "#F1A715",
      //   button: "RANKINGS",
      //   onClick: () => { }
      // }
      !showBetaStuff ? undefined : null,
      null,
      !showBetaStuff
        ? undefined
        : {
            title: "RECRUITING OFFICE",
            subtitle: (
              <>
                <br />
                <span style={{ fontSize: "0.75ch" }}>
                  HAVE A FRIEND WHO'D LIKE TO JOIN?
                  <br />
                  <br />
                  VOUCH FOR THEM TODAY!
                </span>
              </>
            ),
            color: "#9751DC",
            button: "RECRUIT",
            // buttonDisabled: true,
            style: {
              minHeight: "15ch",
            },
            onClick: () => {
              const buttonDisabled = SIPHONED_MANA > 0 || processing;
              const CopyRefLink = async () => {
                // copy string to url
                if (!refInfo) {
                  alert("Ref info not loaded yet!");
                  return;
                }
                return navigator.clipboard
                  .writeText(REF_LINK)
                  .then(() => alert("copied to clipboard!"));
              };
              BringUpMenu(
                `RECRUITS`,
                {
                  backgroundColor: "#9751DC",
                },
                RecruitPage({CopyRefLink, REF_LINK: refInfo?.id , FLOATING_MANA, SIPHON_RATE, SIPHONED_MANA, buttonDisabled, setProcessing})
              );
            },
          },
    ]
      .reduce((acc, curr) => {
        if (curr || curr === null) {
          acc.push(curr);
        }
        return acc;
      }, [])
      .map((a, i) => {
        if (!!a && !a.id) a.id = i;
        return a;
      });
  }

  function BringUpMenu(title, style, content) {
    setPullUpStyle(style);
    setPullUpTitle(title);
    pullUpContent.current = content;
  }
}

function RenderLibrary({ userData }) {
  const [manaForStudying, setManaForStudying] = useState(
    userData?.stats?.mana || "0"
  );
  const [processing, setProcessing] = useState(false);

  const { xp, time, time_formatted } = DetermineXPAndTime(manaForStudying);

  const [ENDSAT, setEndsAt] = useState(userData?.stats?.activity?.endsAt);
  const [SECONDS_TILL_END, setSeconds] = useState(0);
  const [MINUTES, setMinutes] = useState(0);

  useEffect(() => {
    if ((ENDSAT && SECONDS_TILL_END < 0) || !ENDSAT) return;
    let _i = setInterval(() => Update(), 100);
    return () => clearInterval(_i);

    function Update() {
      const _s = ENDSAT.seconds - Date.now() / 1000;
      setSeconds(_s);
      setMinutes(Math.floor(_s / 60));
    }
  }, [ENDSAT, SECONDS_TILL_END]);

  useEffect(() => {
    const ENDSAT = userData?.stats?.activity?.endsAt;
    setEndsAt(ENDSAT);
    if (!ENDSAT) {
      setSeconds(0);
      setMinutes(0);
      return;
    }
    const _s = ENDSAT?.seconds - Date.now() / 1000;
    setSeconds(_s);
    setMinutes(Math.floor(_s / 60));
  }, [userData]);
  const MANA = userData?.stats?.mana || 0;
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {userData.stats.activity ? (
          <div style={{ fontSize: "1ch", margin: "1em", lineHeight: "1.25em" }}>
            CURRENTLY <span style={{ color: "#4300ff" }}>STUDYING</span>
          </div>
        ) : (
          <div
            style={{
              fontSize: "0.75ch",
              margin: "0 2em 0.5em 0em",
              textAlign: "left",
              width: "25ch",
              maxWidth: "90%",
            }}
          >
            STUDY DURATION
          </div>
        )}

        {/* slider bar in dark blue to select a value from 0 - current mana */}
        {userData.stats.activity ? (
          <>
            <br />
            <button
              disabled={processing || SECONDS_TILL_END >= 0}
              style={{
                outline: "none",
                border: "none",
                borderRadius: ".25em",
                minWidth: "15ch",
                padding: ".75em 2em",
              }}
              onClick={
                processing || SECONDS_TILL_END >= 0
                  ? () => {}
                  : async () => {
                      // firebase callable
                      setProcessing(true);
                      await httpsCallable(
                        getFunctions(),
                        "claimXPFromSession"
                      )({}).catch((err) => {
                        return { err };
                      });
                      setProcessing(false);
                    }
              }
            >
              {processing ? "..." : `CLAIM ${XPEarned(userData).toFixed(1)} XP`}
            </button>
          </>
        ) : (
          <>
            <input
              type="range"
              min="0"
              max={MANA}
              onChange={(e) => setManaForStudying(e.target.value)}
              value={manaForStudying}
              step="1"
              style={{
                width: "25ch",
                maxWidth: "90%",
                margin: "auto 1em",
                color: "white",
              }}
            />
            <div
              style={{
                fontSize: "0.82em5ch",
                margin: "0.5em 0 0 0em",
                textAlign: "right",
                width: "25ch",
                maxWidth: "90%",
              }}
            >
              <span style={{ color: "#00090b" }}>
                {ENDSAT
                  ? MINUTES > 0
                    ? `${Math.floor(MINUTES / 60)}h ${(
                        MINUTES % 60
                      ).toPrecision(2)}m`
                    : SECONDS_TILL_END >= 0
                    ? `${SECONDS_TILL_END.toPrecision(3)}s`
                    : "DONE!"
                  : time_formatted}
              </span>
            </div>
            <br />
            <div
              style={{
                fontSize: "0.75ch",
                margin: "0 0 0.5em 2em",
                textAlign: "right",
                width: "25ch",
                maxWidth: "90%",
              }}
            >
              MANA COST
              {/* <span
                style={{
                  color: "#14c6ee",
                  background: "#ffffffcc",
                  padding: "0.25em 0.5em",
                  borderRadius: "0.5em",
                }}
              >
                MANA
              </span> */}
            </div>
            <input
              type="number"
              style={{
                width: "25ch",
                maxWidth: "90%",
                margin: "auto 1em",
                color: "white",
              }}
              onChange={(e) =>
                Number(e.target.value) > MANA
                  ? setManaForStudying(MANA)
                  : setManaForStudying(e.target.value)
              }
              value={manaForStudying}
              min={0}
              max={MANA}
            />
            <div style={{ fontSize: "0.75ch", margin: "2em 0 1em 0" }}>
              GAIN <span style={{ color: "#00090b" }}>{xp.toFixed(1)}</span> XP
            </div>
            <br />
            <button
              disabled={DisableStudyButton()}
              style={{
                outline: "none",
                border: "none",
                borderRadius: ".25em",
                minWidth: "15ch",
                padding: ".75em 2em",
              }}
              onClick={
                DisableStudyButton()
                  ? () => {}
                  : async () => {
                      // firebase callable
                      setProcessing(true);
                      const result = await httpsCallable(
                        getFunctions(),
                        "startLibrarySession"
                      )({ mana: manaForStudying });
                      setProcessing(false);
                    }
              }
            >
              {userData?.stats?.activity
                ? "STUDYING"
                : processing
                ? "..."
                : DisableStudyButton()
                ? "NOT ENOUGH MANA"
                : "STUDY"}
            </button>
            <br />
          </>
        )}

        <div
          style={{ fontSize: "0.75ch", margin: "0.9em", lineHeight: "1.9em" }}
        >
          MORE{" "}
          <span
            style={{
              color: "#14c6ee",
              background: "#ffffffcc",
              padding: "0.25em 0.5em",
              borderRadius: "0.5em",
            }}
          >
            MANA
          </span>{" "}
          INCREASES HOW LONG YOU CAN{" "}
          <span style={{ color: "#4300ff" }}>STUDY</span> FOR IN ONE GO, BUT IS
          BEST SPENT IN SMALL DOSES FOR MAX USAGE
        </div>
      </div>
    </>
  );

  function DisableStudyButton() {
    return (
      parseInt(manaForStudying) < 1 || userData.stats.activity || processing
    );
  }

  function DetermineXPAndTime(mana) {
    mana = Number(mana);
    if (isNaN(mana)) return { xp: 0, time: 0 };
    // xp is equal to amount of time spent studyin
    const MINUTES = Math.pow(mana * mana, 1 / 2.37604);
    const XP_RATE_PER_MIN = 1.33 + (mana * 0.08 > 2 ? 2 : mana * 0.08);
    const XP = MINUTES * XP_RATE_PER_MIN;

    return {
      xp: XP,
      time: MINUTES,
      time_formatted: `${Math.floor(MINUTES / 60)}h ${(
        MINUTES % 60
      ).toPrecision(2)}m`,
    };
  }
}

function TrollBox({ showChat, pullUpStyle, setShowChat }) {
  const [isHovering, setIsHovering] = useState(false);
  const [stickied, setStickied] = useState(false); // set to true if a new message is received when isHovering is true

  useEffect(() => {
    if (isHovering) {
      setStickied(true);
    }
  }, [isHovering]);

  // create useEffect to listen to when user is hovering over chat history
  useEffect(() => {
    const el = document.getElementsByClassName("chat-messages")[0];
    if (!el || !showChat) return;
    let listener1 = el.addEventListener("mouseover", () => {
      setIsHovering(true);
    });
    let listener2 = el.addEventListener("mouseleave", () => {
      setIsHovering(false);
    });
    // listen to scroll event
    let listener3 = el.addEventListener("scroll", (e) => {
      if (el.scrollTop + el.offsetHeight + 20 < el.scrollHeight) {
        setStickied(true);
      } else if (el.scrollTop + el.offsetHeight + 10 >= el.scrollHeight) {
        setStickied(false);
        // if greater than 80px and hovering, sticky
      } else {
        setStickied(false);
      }
    });
    return () => {
      el.removeEventListener("mouseover", listener1);
      el.removeEventListener("mouseleave", listener2);
      el.removeEventListener("scroll", listener3);
    };
  }, [showChat, isHovering, stickied]);

  useEffect(() => {
    const el = document.getElementsByClassName("chat-messages")[0];

    if (showChat && !stickied && el) {
      // set scroll to bottom
      el.scrollTop = el.scrollHeight;
    }
  }, [showChat, stickied]);

  return (
    <Transition in={showChat} timeout={800}>
      {(state) => {
        return (
          <>
            <div
              className={`pull-up pull-up-chat overlay ${state} ${
                pullUpStyle?.invert ? "invert" : ""
              }`}
              style={{ backgroundColor: "rgb(77, 21, 198)" }}
            >
              <div
                className={`pull-up-exit ${state} ${
                  pullUpStyle?.invert ? "invert" : ""
                }`}
              >
                <button
                  style={{ borderColor: "#00090b", backgroundColor: "#00090b" }}
                  onClick={() => {
                    setShowChat(false);
                  }}
                >
                  <span
                    style={{
                      color: "rgb(77, 21, 198)",
                    }}
                  >
                    X
                  </span>
                </button>
              </div>
              <h1 className={`${pullUpStyle?.invert ? "invert" : ""}`}>
                TROLLBOX
              </h1>
              <div
                className={`pull-up-content pull-up-chat-content ${state} ${
                  pullUpStyle?.invert ? "invert" : ""
                }`}
              >
                <ul className="chat-messages">
                  {Array.from({ length: 20 }).map((_, i) => {
                    const RANDOM_GEN_ADDY =
                      "0x" + Math.random().toString(16).slice(2, 12);
                    return (
                      <li key={i}>
                        <div className="chat-message">
                          <div className="chat-message-author-address">
                            {/* Random address formatted as 0x111...111 */}
                            {RANDOM_GEN_ADDY.slice(0, 5)}...
                            {RANDOM_GEN_ADDY.slice(12 - 3, 12)}
                          </div>

                          <div className="chat-message-bottom">
                            <div className="chat-message-author">
                              <div className="chat-message-author-avatar">
                                <Blockies
                                  size={10}
                                  scale={4}
                                  color="#32e089"
                                  spotColor="#4d15c6"
                                  className="identicon"
                                />
                                <div className="chat-message-author-level">
                                  {GenerateBetween(1, 100)}
                                </div>
                              </div>
                            </div>
                            <div className="chat-message-content">
                              {/* Random length lorem ipsum */}
                              <span key={i}>
                                {"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                                  .split(" ")
                                  .slice(
                                    GenerateBetween(0, 5),
                                    GenerateBetween(10, 40)
                                  )
                                  .join(" ")}
                              </span>
                            </div>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
                <div className="chat-input">
                  <textarea placeholder="Type a message..."></textarea>
                </div>
              </div>
            </div>
          </>
        );
      }}
    </Transition>
  );
}

function GenerateBetween(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

function XPEarned(UserData) {
  const last_claim =
    UserData?.stats?.activity?.metadata?.lastClaimed ||
    UserData?.stats?.activity?.startedAt ||
    new Date();

  const time_till_end =
    new Date(UserData?.stats?.activity?.endsAt.seconds * 1000).getTime() -
    new Date().getTime();
  const time_since_last_claim =
    (time_till_end > 0
      ? new Date()
      : new Date(UserData?.stats?.activity?.endsAt.seconds * 1000)
    ).getTime() -
    (isNaN(last_claim) ? last_claim?.getTime() : last_claim.seconds * 1000);
  const minutes_since_last_claim = time_since_last_claim / 60000;
  const xp_to_claim =
    UserData?.stats?.activity?.metadata?.xpRate * minutes_since_last_claim;

  return xp_to_claim;
}
