import { useEffect, useState } from "react";
import { rxActivity } from "../_helpers/rx";

export function useActivityFeed() {
    const [feed, setFeed] = useState([]);

    useEffect(() => {
        let l = rxActivity.subscribe(f => {
            setFeed(f);
        });
        return () => l.unsubscribe();
    }, []);

    return feed;
}