import { ethers } from 'ethers';
import { useEffect, useState } from 'react';
import { BehaviorSubject } from 'rxjs';
import { web3, web3enable } from '../_helpers/web3';

// connect to current provider and get the current account
export function useSigner({ shouldConnect, shouldSign }) {
  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [signer, setSigner] = useState();

  useEffect(() => {
    if (!shouldConnect || !shouldSign) { setSigner(); setAccounts([]); return; }
    ;(async () => {
      let connected = web3 ? await web3enable().then(t => {console.log("Connected!"); return t}) : false;
      if(!connected) return;
      const EthersProvider = new ethers.providers.Web3Provider(web3.currentProvider);
      const signer = await EthersProvider.getSigner();
      const accs = await web3.eth.getAccounts().then(x => { console.log(x); return x; });
      setSigner(signer);
      setLoading(false);
      setAccounts(accs);
    })();
  }, [shouldConnect, shouldSign]);

  return { accounts, loading, signer };
}
