import { useEffect, useState } from "react";

  export function useTips(level, userData) {
  const [index, setIndex] = useState(0);

  const [TIPS_FOR_LEVELS, setTipsForLevels] = useState({});

  useEffect(() => {
    if (!userData) return;
    setTipsForLevels({
      all: GenericTips(),
      0: Level0Tips(),
      1: Level1Tips(),
      // 2: Level2Tips(),
    });

    function GenericTips() {
      let tips = [];

      const xp = userData.stats.xp;
      const mana = userData.stats.mana;
      const maxMana = userData.stats.manaMax;
      const totalXP = userData.stats.totalXP;
      const xpToNextLevel = userData.stats.xpToNextLevel;
      const remainingXP = xpToNextLevel - xp;
      const lvl = userData?.stats.level || 0;

      console.log(remainingXP, remainingXP / xpToNextLevel);
      if (
        remainingXP / xpToNextLevel < 0.55 &&
        remainingXP / xpToNextLevel > 0.45
      ) {
        tips.push(
          SelectRandomElement([<div>Wooo, we're half way there!</div>])
        );
      } else if (
        remainingXP / xpToNextLevel <= 1 &&
        remainingXP / xpToNextLevel >= 0.99 &&
        lvl > 0
      ) {
        tips.push(
          SelectRandomElement([
            <div>New level, new me</div>,
            lvl > 0 ? (
              <div>How's it feel to be level {lvl}?</div>
            ) : (
              <div>Earn mana by completing quests!</div>
            ),
          ])
        );
      } else if (remainingXP / xpToNextLevel <= 0.05) {
        // select from words of motivation
        tips.push(
          SelectRandomElement([
            <div>
              You're almost to the next level! Just a little bit more to go!
            </div>,
          ])
        );
      }

      let manaElements = [];
      if (mana == 69)
        manaElements.push(
          <div>
            <b>69 mana</b>, nice
          </div>
        );
      if (mana > 9000 && mana < 10000)
        manaElements.push(<div>Your mana! It's over 9000!</div>);
      if (mana / maxMana > 0.9)
        manaElements.push(
          <div>Your mana is over 90% full! Time to study!</div>
        );
      if (mana / maxMana > 0)
        manaElements.push(<div>Mana reserves running low!</div>);
      if (mana == 0) manaElements.push(<div>You're out of mana!</div>);

      if (manaElements.length > 0) {
        tips.push(SelectRandomElement(manaElements));
      }
      console.log(mana/maxMana)

      return tips;

      function SelectRandomElement(array) {
        if (array.length === 0) throw new Error("Empty array");
        console.log(array);
        if (array.length == 1) return array[0];
        const has_weight_key = array[0].hasOwnProperty("weight");
        if (!has_weight_key)
          array = array.map((e) => ({
            ...(typeof e === "object" ? { ...e } : { value: e }),
            weight: e.weight || 1,
          }));
        // each element has el.weight which is its relative probability
        // add all weights then pick a random number between 0 and the sum

        let sum = array.reduce((acc, el) => acc + (el?.weight || 1), 0);
        let random_number = Math.floor(Math.random() * sum);

        const el = array[random_number];
        return el?.value ? el?.value : el;
      }
    }

    function Level0Tips() {
      let BASE_TIPS = [
        <div>Welcome to the academy!</div>,
        <div>
          To get started, you'll have to earn <b>mana</b>.
        </div>,
      ];

      const xp = userData.stats.xp;
      const xpToNextLevel = userData.stats.xpToNextLevel;
      const mana = userData.stats.mana;

      if (mana >= 10 && xp <= 1)
        // User is has some mana but no XP
        BASE_TIPS = [
          <div>
            Congrats on reaching <b>{userData.stats.mana} mana!</b> Now convert
            it into <b>XP</b> with the library
          </div>,
        ];
      else if (xp >= 1)
        // User has XP
        BASE_TIPS = [
          <div>
            Success! Your first XP! Now just <b>{(xpToNextLevel - xp).toFixed(1)} XP</b> left
            to go!
          </div>,
        ];
      if (userData.stats.activity?.type === "STUDYING") {
        // User is studying
        // check if past the study time
        const endsAt = new Date(userData.stats.activity.endsAt.seconds * 1000);
        const now = new Date();
        if (now > endsAt) {
          BASE_TIPS = [
            <div>Looks like your study session is done, time to claim that XP!</div>
          ]
        } else {
        BASE_TIPS = [
          <div>
            I see you've taken up your studies! At this rate, you'll be able to join the rest of the students in no time!
          </div>,
        ];
      }
      }

      BASE_TIPS.push(<div>Soon you'll unlock <b>The Trollbox</b> where you can chat with other students!</div>)

      return BASE_TIPS;
    }

    function Level1Tips() {
      let BASE_TIPS = [
        <div>Already level 1? You must be getting the hang of it now! Great job!</div>,
      ];

      const xp = userData.stats.xp;
      const xpToNextLevel = userData.stats.xpToNextLevel;
      const mana = userData.stats.mana;
      
      if(xp > xpToNextLevel / 10)
        BASE_TIPS = []
      
      BASE_TIPS.push(<div>Once you reach <b>level 2</b> you will be granted access to <b>The Trollbox</b>, a lounge to hang out with other students!</div>)
      BASE_TIPS.push(<div>Notice the activity feed? Mana earned from you & other students will show up here!</div>)

      return BASE_TIPS;
    }
  }, [userData]);
  const tips = CombineAllTips();

  useEffect(() => {
    let timeout = setTimeout(() => {
      increment();
      }, 30000);
    return () => clearTimeout(timeout);
  }, [TIPS_FOR_LEVELS, index])

  //   once a minute, increment the index
  useEffect(() => {
    const TipsForCurrentLevel = CombineAllTips();
    if (
      !userData ||
      !TIPS_FOR_LEVELS ||
      !TipsForCurrentLevel ||
      TipsForCurrentLevel.length <= 1
    )
      return;
    const interval = setInterval(() => {
      // check if page is currently active tab
      if (document.hasFocus()) {
        setIndex((index + 1) % TipsForCurrentLevel.length);
      }
    }, 60000);
    return () => clearInterval(interval);
  }, [userData, level, TIPS_FOR_LEVELS]);

  useEffect(() => {
    if (index > tips.length - 1) {
      setIndex(0);
    }
  }, [level, index]);

  return { tip: tips[index] || null, tips, index, increment, decrement };

  function CombineAllTips() {
    return [
      ...(TIPS_FOR_LEVELS[level] || []),
      ...(TIPS_FOR_LEVELS["all"] || []),
    ];
  }

  function increment() {
    if (tips.length <= 1) return;
    setIndex(index + (1 % tips.length));
  }
  function decrement() {
    if (tips.length <= 1) return;
    setIndex(index <= 0 ? tips.length - 1 : index - 1);
  }
}
