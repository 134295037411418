import { BehaviorSubject, combineLatestWith, filter, map } from "rxjs";
import { rxWindowFocus } from "./rxWindowFocus";

export const rxActivityBase = new BehaviorSubject([]);

export const rxActivity = rxActivityBase.pipe(
    combineLatestWith(rxWindowFocus),
    filter(([_, isFocused]) => isFocused),
    map(([activities, _]) => activities),
);

