import './App.css';
import { useEffect, useState } from 'react';
import { useSigner } from './_hooks/useProvider';
// Import the functions you need from the SDKs you need
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions"
import { Dashboard } from './components/Dashboard.jsx';
import { app } from './firebase';
import { useFirebaseUser } from "./_hooks/useFirebaseUser";
import { Transition } from 'react-transition-group';
import {socket} from './_helpers/socket';
import "./_helpers/canvas";
// socket.connect();
(() => socket)();

window.logout = () => {
  return getAuth(app).signOut();
}
let last_sign_in = 0;
const LOADED_AT = Date.now();
function App() {
  const [shouldConnect, setShouldConnect] = useState(false);
  const [shouldSign, setShouldSign] = useState(false);
  const { accounts, loading, signer } = useSigner({ shouldConnect, shouldSign });
  const [waitingForCode, setWaitingForCode] = useState(false);
  const [referrer, setReferrer] = useState(localStorage.getItem('refcode'));

  const { user } = useFirebaseUser();

  useEffect(() => {
    if (user && !isSignedIn()) {
      setShouldConnect(true);
      setShouldSign(true);
    }
  }, [user])

  useEffect(() => {
    if (!isSignedIn()) {
      if (!signer) {
        return;
      } else {
        if (last_sign_in + 1000 > Date.now()) {
          return;
        }
        last_sign_in = Date.now();
        ; (async () => await AttemptSignIn())();
      }
    }
  }, [user, shouldSign, shouldConnect, signer, accounts]);

  useEffect(() => {
    // from local storage check for 'refcode'
    if (referrer && !user && Date.now() - LOADED_AT > 1000) {
      ; (async () => {
        // check if refcode is valid
        const ref = await httpsCallable(getFunctions(app), 'checkRefCode')({ refcode: referrer });
        if (!ref.data.valid) {
          localStorage.removeItem('refcode');
          setReferrer('');
        } else {
          // if logged in delte refcode from local storage
          if(isSignedIn()) {
            localStorage.removeItem('refcode');
          }
        }
        // clear the search param
      })();
    } else {
      // check query for ref
      const query = new URLSearchParams(window.location.search);
      const refcode = query.get('r');
      if (refcode) {
        ; (async () => {
          // check if refcode is valid
          // const ref = await httpsCallable(getFunctions(app), 'checkRefCode')({ refcode });

          // if (ref.data.valid) {
          localStorage.setItem('refcode', refcode);
          setReferrer(refcode);
          window.location.href = window.location.href.split('?')[0];
          // }
        })();
      }
    }
  }, [referrer, user])


  return (
    <div className="App">
      <Transition in={!!isSignedIn()} timeout={2000}>
        {state =>
          <>
            {state == "entered" ? <Dashboard provider={signer} accounts={accounts} user={user} /> : null}
            <div className={`background-design ${state}`} />
            <div className={`background-design ${state}`} />
            <div className={`background ${state}`} />
            {!isSignedIn() ? <h1 className={`sign-in ${state} ${waitingForCode ? "spin" : ""}`} onClick={() => { setShouldConnect(true); setShouldSign(true); setWaitingForCode(true); }}>§</h1> : null}
          </>}
      </Transition>
    </div>
  );

  async function AttemptSignIn() {
    if (!accounts[0] || !signer && referrer == null) return;
    const functions = await getFunctions(app);
    const generateToken = await httpsCallable(functions, "generateToken");
    // disable cors
    const verifyToken = await httpsCallable(functions, "verifyToken");
    try {
      setWaitingForCode(true);
      const { data } = await generateToken({ address: accounts[0], referrer: referrer?.length ? referrer : null });

      let signingToken = data.token;
      console.log(signingToken);

      const SigningMessage = `I wish to sign into The Academy\n${accounts[0]}\n${signingToken}`;
      const signed = await signer.signMessage(SigningMessage);

      const { data: result } = await verifyToken({
        hash: signed,
        message: SigningMessage
      });
      // log into firebase with the result.auth token
      const auth = getAuth(app);
      await signInWithCustomToken(auth, result.auth).then(() => {
        // setShouldConnect(false);
        setWaitingForCode(false);
      });
    } catch (err) {
      console.warn(err);
      window.alert("The Academy could not let you in at this time. Follow @StudentsOfInfo on twitter for updates");
      Finished();
    }

    function Finished() {
      setShouldConnect(false);
      setShouldSign(false);
      setWaitingForCode(false);
    }
  }

  function isSignedIn() {
    return !!accounts.length && signer && user;
  }
}

export default App;
