import "./canvas.css";

var c = document.getElementById("c");
var ctx = c.getContext("2d");

//making the canvas full screen
c.height = window.innerHeight;
c.width = window.innerWidth;

//chinese characters - taken from the unicode charset
var knowledge = "╎ᔑᒲᔑᓭℸ ̣⚍↸ᒷリℸ ⎓╎リ";
//converting the string into an array of single characters
knowledge = knowledge.split("");

var font_size = 15;
var columns = c.width/(font_size); //number of columns for the rain
//an array of drops - one per column
var drops = [];
//x below is the x coordinate
//1 = y co-ordinate of the drop(same for every drop initially)
for(var x = 0; x < columns * 3; x++)
	drops[x] = c.height * Math.random(); 
//drawing the characters
let _x = 0;
function draw()
{
    _x += 1;
    _x = _x % 15;
	//Black BG for the canvas
	//translucent BG to show trail
	ctx.fillStyle = "rgba(0, 0, 0, 0.1)";
	ctx.fillRect(0, 0, c.width, c.height);
	
	ctx.fillStyle = Math.random() < 0.5 ? "#fcfcfc11" : "#CEE9EF33"; //green text
	ctx.font = font_size + "px arial";
	//looping over drops
	for(var i = 0; i < drops.length; i++)
	{
        
		//a random chinese character to print
		var text = knowledge[Math.floor(Math.random()*knowledge.length)];
		//x = i*font_size, y = value of drops[i]*font_size
		ctx.fillText(text, i*font_size % c.width, drops[i]*font_size);
		
		//sending the drop back to the top randomly after it has crossed the screen
		//adding a randomness to the reset to make the drops scattered on the Y axis
		if(Math.random() > 0.985)
			drops[i] = Math.floor(((c.height /2) * Math.random()));
		
		//incrementing Y coordinate
        if(i%2) {
            drops[i] -= i%3 == 0 ? 0.5 : 0.25;
        } else {
            drops[i] += i%4 == 0 ? 0.5 : 0.25;
        }
        
	}
}

setInterval(draw, 33);



