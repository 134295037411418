
export function Card({ title, subtitle, button, color, onClick, buttonDisabled, style, invert, id, setSelectedCard }) {
  return <div className="card" style={{
    backgroundColor: color,
    ...style,
    color: invert ? color : null
  }}>
    <div className={`card-title ${invert ? "invert" : ''}`}>{title}</div>
    <div className={`card-subtitle ${invert ? "invert" : ''}`}>{subtitle}</div>
    <button className={`button  ${invert ? "invert" : ''}`} onClick={buttonDisabled ? null : (e) => {setSelectedCard(id);}} disabled={buttonDisabled} style={{ color: invert ? "#000" : color, border: "none", outline: "none", padding: "0.5ch 1ch", }}>{button}</button>
  </div>;
}
