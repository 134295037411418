import { useEffect, useState } from 'react';
import { getAuth } from "firebase/auth";
import { app } from '../firebase';
import { authState } from "rxfire/auth"
import {docData} from 'rxfire/firestore';
import {doc as document, getFirestore} from 'firebase/firestore';
import { filter, switchMap } from 'rxjs';
import { WrapAsSubject } from '../_helpers/WrapAsSubject';

// rxjs observable of firebase user state
const rxAuth = WrapAsSubject(authState(getAuth(app)));

const rxUserData = WrapAsSubject(rxAuth.pipe(
  filter(user => !!user),
  switchMap(user => docData(document(getFirestore(), `users/${user.uid}`))),
), null);


export function useFirebaseUser() {
  const [user, setUser] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    let l = rxAuth.subscribe(user => setUser(user));
    return () => l.unsubscribe();
  }, []);
  
  useEffect(() => {
    let l = rxUserData.subscribe(data => setData(data));
    return () => l.unsubscribe();
  }, [])

  return {user, data};
}

