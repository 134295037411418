import { getAuth } from 'firebase/auth';
import { user } from 'rxfire/auth';
import { filter, first, firstValueFrom } from 'rxjs';
import {io} from 'socket.io-client';
import { rxActivity } from './rx';
import { rxActivityBase } from './rx/rxActivity';

export const socket = io('studentsof.info', {
    path: '/ws/socket.io',
});


socket.on('connect', async () => {
    await firstValueFrom(user(getAuth()).pipe(filter(x => !!x)))
        .then(user => {
            return user.getIdToken();
        })
        .then(tokenId => {
            return socket.emit("verify", {
                token: tokenId
            }, (err, loggedIn) => {
                if (err) { 
                    console.error(err);
                }
                if (loggedIn) {
                    SubscribeToFeeds();
                }
            })
        })

    socket.on('history', (data) => {
        rxActivityBase.next(data);
    })
    

    async function SubscribeToFeeds() {
        return socket.emit("subscribe", {
            feeds: ['activity', 'chat']
        }, (err, data) => {
            if (err) {
                console.error(err);
            }
            rxActivityBase.next(data[0]);;
        })
    }
});

socket.on('disconnect', () => {
    console.log('disconnected');
})

console.log('socket id: ', socket.id);