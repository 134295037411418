import Web3 from "web3";
import { nextAddresses, nextSigner, nextChain } from './rx';

let web3;
const E = window.ethereum || window.web3;
if (window.ethereum) {
    web3 = new Web3(window.ethereum);
} else if (window.web3) {
    web3 = new Web3(window.web3.currentProvider);
} else {
    alert("Your browser currently does not support web3. Please install MetaMask to continue.");
    web3 = null;
}

// Called by useProvider.js, shows user a MetaMask prompt
const web3enable = async () => web3 ? E.enable().then(() => true).catch(err => { console.log(err); return false }) : false


E?.on('connect', async ({ chainId }) => {
    nextChain(chainId);
    nextSigner(await web3.eth.getSigner());
    nextAddresses(await web3.eth.getAccounts());
});
E?.on('accountsChanged', (accounts) => { nextAddresses(accounts); });


export { web3, web3enable };