import { getApp } from "firebase/app";
import { getFunctions, httpsCallable } from "firebase/functions";

export function RecruitPage({ CopyRefLink, REF_LINK, FLOATING_MANA, SIPHON_RATE, SIPHONED_MANA, buttonDisabled, setProcessing }) {
  return <>
    <p
      className="centered"
      style={{
        color: "inherit",
        fontSize: "0.66em",
        maxWidth: "25em",
        display: "block",
        lineHeight: "1.75em",
      }}
    >
      Your recruit code is...
    </p>
    <br />
    <div
      className="centered"
      onClick={CopyRefLink}
      style={{
        position: "relative",
        maxWidth: "25em",
        width: "100%",
      }}
    >
      <input
        className="ref-input"
        value={REF_LINK}
        style={{
          width: "calc(100% - 3em)",
          textAlign: "center",
          margin: "auto",
          height: " 3em",
          fontSize: "0.66em",
        }}
        disabled={true} />
    </div>
    <br />
    <p
      className="centered"
      style={{
        color: "inherit",
        fontSize: "0.66em",
        maxWidth: "35em",
        display: "block",
        lineHeight: "1.75em",
      }}
    >
      and receive
      <span style={{ color: "#14c6ee" }}> 15%</span> of all mana
      generated by your recruits as{" "}
      <i style={{ color: "#14c6ee" }}>Floating Mana</i>
      {/*, the limit for which is increased with <i style={{color: "#eb6af2"}}>Crystals</i> */}
    </p>
    <br />
    <div
      style={{
        color: "inherit",
        width: "calc(100% - 4em)",
        margin: "auto",
      }}
    >
      <p
        style={{
          color: "inherit",
          fontSize: "0.66em",
          textAlign: "left",
          maxWidth: "35em",
          display: "block",
          lineHeight: "1.75em",
        }}
      >
        STATS
      </p>
      <hr />
      <p
        style={{
          color: "inherit",
          fontSize: "0.66em",
          textAlign: "left",
          maxWidth: "35em",
          display: "block",
          lineHeight: "1.75em",
        }}
      >
        STUDENTS RECRUITED:{" "}
        <span style={{ color: "#F1A715" }}>{0}</span>
      </p>
      {/* <p style={{color: "inherit", fontSize: '0.66em', textAlign: 'left', maxWidth: "35em", display: 'block', lineHeight: '1.75em'}}>
            TOTAL CRYSTALS: <span style={{color: "#eb6af2"}}>{0}</span>
          </p> */}
      <p
        style={{
          color: "inherit",
          fontSize: "0.66em",
          textAlign: "left",
          maxWidth: "35em",
          display: "block",
          lineHeight: "1.75em",
        }}
      >
        NEARBY FLOATING MANA:{" "}
        <span style={{ color: "#14c6ee" }}>{FLOATING_MANA}</span>
      </p>
      <p
        style={{
          color: "inherit",
          fontSize: "0.66em",
          textAlign: "left",
          maxWidth: "35em",
          display: "block",
          lineHeight: "1.75em",
        }}
      >
        SIPHON RATE:{" "}
        <span style={{ color: "inherit" }}>
          {SIPHON_RATE}% (8h)
        </span>
      </p>
      <p
        style={{
          color: "inherit",
          fontSize: "0.66em",
          textAlign: "left",
          maxWidth: "35em",
          display: "block",
          lineHeight: "1.75em",
        }}
      >
        SIPHONED MANA:{" "}
        <span style={{ color: "#14c6ee" }}>{SIPHONED_MANA}</span>
      </p>
      <br />
      <button
        style={{
          background: "#14c6ee",
          color: "inherit",
          fontSize: "0.75em",
          border: "none",
          padding: "1em 2.5em",
          borderRadius: "0.5em",
          cursor: "pointer",
          opacity: SIPHONED_MANA > 0 ? 1 : 0.3,
        }}
        disabled={buttonDisabled}
        onClick={!buttonDisabled
          ? async () => {
            setProcessing(true);
            await httpsCallable(
              getFunctions(getApp()),
              "claimRefMana"
            );
          }
          : () => { }}
      >
        {!buttonDisabled ? (
          <>CLAIM {SIPHONED_MANA} MANA</>
        ) : (
          <>CLAIMING...</>
        )}
      </button>
    </div>
  </>;
}
